<template>
  <v-card
    outlined
    :height="slide ? '330' : ''"
    :width="slide ? '230' : ''"
    class="fill-height rounded-lg"
    @click="openModal"
    v-if="content"
    >
    <product-detail
      v-if="modal"
      @close="modal = false"
      :productId="content.id"
      ref="productDetail"
      ></product-detail>

    <template
      v-if="content.images.length"
      >
      <v-img
        class="rounded-t-lg"
        :width="slide ? '230' : ''"
        :height="slide ? '230' : ''"
        :src="url + content.images[0].thumbUrl"
        ></v-img>
    </template>
    <template
      v-else>
      <v-img
        class="rounded-t-lg"
        :width="slide ? '230' : ''"
        :height="slide ? '230' : '185'"
        >
      </v-img>
    </template>

    <div
      class="mt-n4 text-center"
      >
      <v-chip
        small
        v-if="!(content.displayFullPrice == content.displayPrice)"
        color="grey lighten-3"
        class="mr-2"
        >
        <span class="grey--text text-decoration-line-through font-weight-regular">{{ content.displayFullPrice }}</span>
      </v-chip>

      <v-chip
        color="primary"
        >
        {{ content.displayPrice }}
      </v-chip>
    </div>

    <v-card-text
      class="py-3"
      >
      <div 
        class="text-subtitle-2 font-weight-regular"
        style="height: 44px"
        :class="!store ? 'description-box' : 'product-box'"
        >
        {{ content.name }}
      </div>
      <div
        v-if="!store"
        class="caption secondary--text font-weight-medium"
        >
        <v-icon small class="mt-n1" color="secondary">mdi-storefront</v-icon> {{ content.store.name }}
      </div>
      <div
        class="text-center mt-1"
        >
        <v-btn
          v-if="content.type == 'store'"
          color="primary"
          class="rounded-xl px-6"
          solo
          x-small
          >
          {{$t('actions.follow')}}
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
const ProductDetail = () => import('@/components/products/Product')

export default {
  data () {
    return {
      modal: false
    }
  },

  props: {
    slide: {
      required: false,
      type: Boolean,
      default: false
    },
    content: {
      type: Object,
      required: true
    },
    store: {
      required: false,
      type: Object,
    }
  },

  components: { ProductDetail },

  computed: {
    url () {
      return process.env.VUE_APP_RAILS_URL
    }
  },

  methods: {
    openModal () {
      this.modal = true
      setTimeout( () => {
        this.$refs.productDetail.dialog = true
      }, 100)
    }
  }
}
</script>
